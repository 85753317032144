import React from "react";
import classNames from "classnames";
import "./text.scss";

export const Text = props => {
    const classnames = classNames("e-text", {
        // size
        "e-text--strong": props.strong,

        // colour variant
        "e-text--size10": props.size10,
        "e-text--size13": props.size13,
        "e-text--size14": props.size14,
        "e-text--size15": props.size15,

        "e-text--white": props.white,
        "e-text--color-third": props.colorThird,
        "e-text--centered": props.centered,
        "e-text--semibold": props.semibold,

    });
    return (
        <p className={props.className ? props.className + ` ` + classnames : classnames}>
            {props.content}
        </p>
    );
};
